.ratings-table td{
  color: #2b2b2b !important;
}

.ratings-table th, .ratings-table td{
  font-size: 13px !important;
  padding: 5px !important;
}

.ratings-table thead tr th{
  text-transform: uppercase !important;
  text-align: center !important;
}

.ratings-table thead tr:first-child th{
  border: none !important;
}

.ratings-table thead tr:first-child th{
  background-color: #f26a3d !important;
  color: #f7fafc !important;
}

.ratings-table.audience-ratings-table thead tr:first-child th:first-child{
  background: transparent !important;
}

.ratings-table thead tr:nth-child(2) th{
  background-color: #f28e6d !important;
  color: #f7fafc !important;
}

.ratings-table tbody > tr:nth-of-type(odd){
  background-color: #cccccc !important;
}

.ratings-table tbody > tr:nth-of-type(even){
  background-color: #d4d4d4 !important;
}

.ratings-table tbody tr td{
  text-align: right !important;
}

.ratings-table tbody tr td .intab-indicator{
  color: #888;
}

.ratings-table tbody tr td:first-child{
  text-align: center !important;
}

.ratings-table thead .sorting:after {
  color: rgba(50, 50, 50, 0.9) !important;
}

.ratings-table thead .sorting_asc:after, .ratings-table thead .sorting_desc:after {
  color: rgba(255, 255, 255, 0.9) !important;
}

.ratings-table thead .sorting:after{
  top: 5px !important;
}

#tv_audience_composition_header li {
  display: inline-block;
  line-height: 30px;
  margin-left: 5px;
  font-weight: 400;
  color: #2b2b2b;
}

#tv_audience_composition_header li i {
  margin: 0;
}

#tv_audience_composition_header h3 {
  margin: 0;
}

#tv_audience_composition_header #book{
  font-size: 18px;
}

#tv_audience_composition_header #day_part, #tv_audience_composition_header #distributor{
  font-size: 14px;
}

#tv_audience_composition_header #programs{
  font-size: 12px;
  font-weight: normal;
}

.ratings-table .dataTables_wrapper{
  min-width: 700px !important;
}






#tv_market_composition_header li {
  display: inline-block;
  line-height: 30px;
  margin-left: 5px;
  font-weight: 400;
  color: #2b2b2b;
}

#tv_market_composition_header li i {
  margin: 0;
}

#tv_market_composition_header h3 {
  margin: 0;
}

#tv_market_composition_header #book{
  font-size: 18px;
}

#tv_market_composition_header #day_part{
  font-size: 14px;
}

.ratings-table td > div{
  display: inline-block;
  width: 50%;
  padding: 0 3px;
}

.ratings-table td > div.right{
  text-align:right;
}

.ratings-table.market-ratings-table thead tr th:first-child,
.ratings-table.market-ratings-table thead tr th:nth-child(2){
  background-color: #2b2b2b !important;
}

.ratings-table.market-ratings-table tbody tr:nth-child(3n) td:first-child,
.ratings-table.market-ratings-table tbody tr:nth-child(3n + 2) td:first-child{
  background-color: #e4e7ea !important;
}

.ratings-table.market-ratings-table tbody tr td{
  text-align: left !important;
}

.ratings-table.market-ratings-table tbody tr td:first-child,
.ratings-table.market-ratings-table tbody tr td:nth-child(2){
  text-align: center !important;
}

.ratings-table.market-ratings-table tbody tr td:first-child{
  white-space: nowrap !important;
  padding: 5px 10px !important;
}

.ratings-table.market-ratings-table tbody tr td{
  min-width: 80px !important;
}

.ratings-table tbody tr:hover td{
  background-color: rgba(241, 89, 37, 0.2);
}



.white-box.nielsen-disclaimer {
  background-color: #2b2b2b;
  color: #e4e7ea;
  font-size: 12px;
  padding: 5px;
  text-align: center;
}



.ratings-table.ratings-tv-program-table tr th:first-child,
.ratings-table.ratings-tv-program-table tr th:nth-child(2){
  background-color: #2b2b2b !important;
}

.ratings-table.ratings-tv-program-table tr td{
  text-align: left !important;
}
.ratings-table.ratings-tv-program-table tr td:nth-child(3),
.ratings-table.ratings-tv-program-table tr td:nth-child(6){
  text-align: center !important;
}

.ratings-table.ratings-tv-program-table tr th::after{
  display: none !important;
}

.ratings-table.ratings-tv-program-table tr th{
  cursor: default;
}

.tv-select-demo-container .radio, .radio-select-demo-container .radio, .comscore-tv-select-demo-container .radio{
  display: inline-block;
  margin-right: 5px;
}

#tv_program_header li {
  display: inline-block;
  line-height: 30px;
  margin-left: 5px;
  font-weight: 400;
  color: #2b2b2b;
}

#tv_program_header li i {
  margin: 0;
}

#tv_program_header h3 {
  margin: 0;
}

#tv_program_header #book{
  font-size: 18px;
}

#tv_program_header #day_part, #tv_program_header #demo{
  font-size: 14px;
}



#radio_audience_composition_header li {
  display: inline-block;
  line-height: 30px;
  margin-left: 5px;
  font-weight: 400;
  color: #2b2b2b;
}

#radio_audience_composition_header li i {
  margin: 0;
}

#radio_audience_composition_header h3 {
  margin: 0;
}

#radio_audience_composition_header #market{
  font-size: 18px;
}

#radio_audience_composition_header #books, #radio_audience_composition_header #day_part,#radio_audience_composition_header #area{
  font-size: 14px;
}






#radio_market_composition_header li {
  display: inline-block;
  line-height: 30px;
  margin-left: 5px;
  font-weight: 400;
  color: #2b2b2b;
}

#radio_market_composition_header li i {
  margin: 0;
}

#radio_market_composition_header h3 {
  margin: 0;
}

#radio_market_composition_header #market{
  font-size: 18px;
}

#radio_market_composition_header #books, #radio_market_composition_header #day_part,#radio_market_composition_header #area{
  font-size: 14px;
}

#radio_comp_market_generate_panel .form-books-container .ms-list{
  height: 140px !important;
}

#radio_comp_market_generate_panel .form-stations-container .ms-list{
  height: 180px !important;
}

#app-ratings-tv-station-ranker{
  .ratings-analysis-container .ag-theme-bootstrap .ag-group-cell {
    font-style: normal !important;
  }

  .ratings-analysis-container .ag-header-container .ag-header-row .ag-header-group-cell-with-group {
    border-left: 1px solid #fff;
  }

  .ratings-analysis-container .ag-header-container .ag-header-row .ag-header-cell {
    border-right: 0 !important;
    border-left: 1px solid #fff;
  }

  .ratings-analysis-container .ag-header-container .ag-header-row:nth-child(1) .ag-header-group-cell-with-group {
    background-color: #f26a3d !important;
  }

  .ratings-analysis-container .ag-header-container .ag-header-row:nth-child(2) .ag-header-group-cell-with-group {
    background-color: #f28e6d !important;
  }

  .ratings-analysis-container .ag-header-container .ag-header-group-cell-label {
    text-align: center;
  }

  .ratings-analysis-container .ag-row-level-0 {
    //background-color: #cccccc !important;
    color: #2b2b2b !important;
  }

  .ratings-analysis-container .ag-row-level-1 {
    background-color: #d4d4d4 !important;
    color: #2b2b2b !important;
  }

  .ratings-analysis-container .ag-row-level-1:nth-child(2n) {
    background-color: #d9d9d9 !important;
  }

  .ratings-analysis-container .ag-row-level-2 {
    background-color: #e5e5e5 !important;
    color: #2b2b2b !important;
  }

  .ratings-analysis-container .ag-row-level-2:nth-child(2n) {
    background-color: #ededed !important;
  }

  .ratings-analysis-container .ag-row-level-2.ag-row-group-expanded {
    background-color: #f28e6d !important;
    color: #fff;
  }

  .program-list {
    display: inline-block;
    margin-right: -15px;
    width: calc(100% - 15px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .program-modal-launch {
    display: inline-block;
    float: right;
    cursor: pointer;
  }

  .program-modal-launch i {
    color: #f26a3d;
  }

  /*.program-calendar tbody > tr > td{*/
  /*vertical-align: top;*/
  /*border-left: 1px solid #2b2b2b;*/
  /*border-bottom: 1px solid #2b2b2b;*/
  /*}*/

  .program-calendar.table > thead > tr > th {
    padding: 2px 0;
  }

  .ag-header-group-cell {
    border-left: 1px solid #fff;
  }

  .ag-header-row .ag-header-group-cell:nth-child(1) {
    border-left: 0;
  }

  .tv_ranker_header li {
    display: inline-block;
    line-height: 30px;
    margin-left: 5px;
  }

  .tv_ranker_header li i {
    margin: 0;
  }

  .tv_ranker_header h3 {
    margin: 0;
  }
}



#ratingsProgramModal .modal-dialog {
  width: 90% !important;
}

.program-calendar tbody > tr > td:last-child {
  border-right: 1px solid #2b2b2b;
}

.program-calendar .day-num {
  text-align: right;
  font-size: 11px;
  font-weight: 600;
  background-color: #f28e6d;
  padding: 2px 5px 0;
  color: #fff;
}

.program-calendar .programs {
  font-size: 12px;
}

.program-calendar .programs ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.program-calendar .programs ul li {
  padding: 0 5px;
  background-color: #efefef;
}

.program-calendar .programs ul li:nth-child(2n) {
  background-color: #fefefe;
}

.program-calendar .times {
  font-size: 11px;
  font-weight: 500;
}

.program-calendar .times ul {
  list-style: none;
  margin-bottom: 0;
  padding: 0;
}

.program-calendar .times ul li {
  padding: 0 5px;
  line-height: 17px;
  background-color: #efefef;
}

.program-calendar .times ul li:nth-child(2n) {
  background-color: #fefefe;
}

.program-calendar tbody > tr > td.inactive-day {
  background-color: #ddd;
}

.program-calendar tbody > tr > td.inactive-day .day-num {
  background-color: #ccc;
}

.program-calendar {
  width: 100%;
}

.program-calendar th {
  width: 13.57%;
  min-width: 100px;
}

.program-calendar th.qh_header {
  width: 5%;
}

.program-calendar th {
  text-align: center;
  background-color: #f26a3d;
  color: #fff;
  border-bottom: 1px solid #2b2b2b !important;
  border-right: 1px solid #2b2b2b !important;
  border-top: 1px solid #2b2b2b !important;
}

.program-calendar thead > tr > th:first-child {
  border-left: 1px solid #2b2b2b;
}

.program-calendar .days td {
  text-align: right;
  font-size: 11px;
  font-weight: 600;
  background-color: #f28e6d;
  padding: 2px 5px;
  color: #fff;
  border-right: 1px #2b2b2b solid !important;
  border-left: 1px #2b2b2b solid !important;
  border-top: 1px #2b2b2b solid !important;
}

.program-calendar .qh td {
  font-size: 12px;
  font-weight: 500;
  padding: 2px 5px;
  color: #2b2b2b;
  border-right: 1px #2b2b2b solid !important;
  border-left: 1px #2b2b2b solid !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.program-calendar .days td.inactive {
  background-color: #ccc;
}

.program-calendar .qh.qh_even {
  background-color: #dedede;
}

.program-calendar .qh.qh_odd {
  background-color: #ededed;
}

.program-calendar .qh .qh_label {
  font-size: 11px;
  font-weight: 700;
  color: #2b2b2b;
}



.ratings-analysis-hide-column a {
  padding: 2px 20px !important;
  font-size: 12px;
  cursor: pointer;
}

.ratings-analysis-hide-column .hidden-column {
  background: #bbb;
  color: #777;
}

.ratings-analysis-hide-column .hidden-column:hover, .hide-column .hidden-column:focus {
  background: #ccc;
  color: #777;
}

#radio-ranker{
  .ratings-analysis-container .ag-theme-bootstrap .ag-group-cell {
    font-style: normal !important;
  }

  .ratings-analysis-container .ag-header-container .ag-header-row .ag-header-group-cell-with-group {
    border-left: 1px solid #fff;
  }

  .ratings-analysis-container .ag-header-container .ag-header-row .ag-header-cell {
    border-right: 0 !important;
    border-left: 1px solid #fff;
  }

  .ratings-analysis-container .ag-header-container .ag-header-row:nth-child(1) .ag-header-group-cell-with-group {
    background-color: #f26a3d !important;
  }

  .ratings-analysis-container .ag-header-container .ag-header-row:nth-child(2) .ag-header-group-cell-with-group {
    background-color: #f28e6d !important;
  }

  .ratings-analysis-container .ag-header-container .ag-header-group-cell-label {
    text-align: center;
  }

  .ratings-analysis-container .ag-row-level-0 {
    background-color: #cccccc !important;
    color: #2b2b2b !important;
  }

  .ratings-analysis-container .ag-row-level-1 {
    background-color: #d4d4d4 !important;
    color: #2b2b2b !important;
  }

  .ratings-analysis-container .ag-row-level-1:nth-child(2n) {
    background-color: #d9d9d9 !important;
  }

  .ratings-analysis-container .ag-row-level-2 {
    background-color: #e5e5e5 !important;
    color: #2b2b2b !important;
  }

  .ratings-analysis-container .ag-row-level-2:nth-child(2n) {
    background-color: #ededed !important;
  }

  .ratings-analysis-container .ag-row-level-2.ag-row-group-expanded {
    background-color: #f28e6d !important;
    color: #fff;
  }

  .ag-header-group-cell {
    border-left: 1px solid #fff;
  }

  .ag-header-row .ag-header-group-cell:nth-child(1) {
    border-left: 0;
  }


  .radio_ranker_header li {
    display: inline-block;
    line-height: 30px;
    margin-left: 5px;
  }

  .radio_ranker_header li i {
    margin: 0;
  }

  .radio_ranker_header h3 {
    margin: 0;
  }
}